<template>
    <div id="line_site">
        <jy-query ref="form" :model="form">
            <jy-query-item prop="routeId" label="线路名称:">
                <!-- <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.routeName"></el-input>
                </div> -->
                <el-select v-model="form.routeId" placeholder="请选择线路">
                    <el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="routeNo" label="线路编号:">
                <el-input placeholder="请输入" v-model="form.routeNo"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('reset')">重置</el-button>
                <el-row>
                    <el-button type="primary" v-on:click="addUdate()" v-if="btnexist('insert')">新增</el-button>
                    <el-button type="primary" @click="batchdelete" v-if="btnexist('batchDelete')">批量删除</el-button>
                    <!-- <el-button type="primary" @click="onexport">导出</el-button>
                    <el-button type="primary" @click="onimport">导入</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="multipleTable" tooltip-effect="dark" @select-all="selectallchange" @select="selectchange" :data="dataList" header-cell-class-name="bg_color" border style="width: 100%">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column type="selection" width="40"></jy-table-column>
            <jy-table-column prop="routeNo" label="线路编号" min-width="120"></jy-table-column>
            <jy-table-column prop="routeName" label="线路名称" min-width="120"></jy-table-column>
            <jy-table-column prop="routeType" label="线路类型" min-width="120"></jy-table-column>
            <jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
            <jy-table-column prop="remark" label="备注" class-name="align_left" min-width="200"></jy-table-column>
            <jy-table-column prop="createTime" label="创建日期" min-width="160"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <add-update-linecar ref="addUpdateOrg" @updateList="getList"></add-update-linecar>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择线路组织" :defaultProps="props"></institutions-tree>
    </div>
</template>
<script>
import addUpdateLinecar from '@/components/pages/admin/resourcesAllocation/lineCar/addUpdateLinecar'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            siteLineList: [],
            props: {
                children: 'children',
                label: 'text',
                value: 'id',
                // multiple: true,
                checkStrictly: true,
            },
            form: {
                routeId: '',
                routeName: '',
                routeNo: '',
            },
            result: {},
            batchdeleteList: [],
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [],
            operateList: [],
            routeOptions:[],

            btnRoute:'/admin/resourcesAllocation/lineCar',
        }
    },
    filters: {
        tissueTypes(val) {
            if (val === 1) {
                return '启用'
            } else if (val === 0) {
                return '禁用'
            } else {
                return val
            }
        },
    },
    watch: {
        'form.routeName': function(val, oldVal) {
            if (val != oldVal) {
                if (this.form.routeName == '') {
                    this.form.routeId = ''
                }
                this.getList()
            }
        },
    },
    mixins:[btnMixins],
    components: {
        addUpdateLinecar,
        institutionsTree,
    },

    computed: {},

    activated() {
        this.getRouteList()
    },

    created() {
        this.getList()
        this.setOperateList()
    },

    methods: {
        getRouteList(){
            let url = '/baseinforoute/queryCurrentAuthorityRoute'
            this.$http.post(url).then(({detail})=>{
                this.routeOptions = detail
            })
        },
        setOperateList() {
            let l = [
                {
                    name: '修改',
                    icon: 'el-icon-edit-outline',
                    fun: this.addUdate,
                    isShow:()=>{
                        return this.btnexist('update')
                    },
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                    isShow:()=>{
                        return this.btnexist('delete')
                    },
                },
                // {
                //     name: '查看',
                //     icon: 'el-icon-view',
                //     fun: this.handlecheck
                // }
            ]
            this.operateList = l
        },
        // 组织
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        addInstitutions(data) {
            console.log(data)
            this.form.routeId = data.id
            this.form.routeName = data.text
        },
        getList() {
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            console.log(option)
            let url = '/routeRelVehicle/getList'
            this.$http.post(url, option).then(({ detail }) => {
                // console.log(detail)
                this.dataList = detail.list
                this.total = detail.total
                this.batchdeleteList = []
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        // 勾选数据
        selectchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        addUdate(option) {
            if (option) {
                this.$refs.addUpdateOrg.init(option, 'update')
            } else {
                //新增
                // if (this.form.showParentId) {
                this.$refs.addUpdateOrg.init(this.form, 'add')
                // } else {
                //   this.$alert('请先选中线路！', '提示', {
                //     type: 'warning'
                //   })
                // }
            }
        },
        handlecheck(option) {
            this.$refs.addUpdateOrg.init(option, 'view')
        },
        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该线路车辆分配?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let option = [row]
                    this.deleteRecord(option)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        // 批量删除
        batchdelete() {
            if (this.batchdeleteList.length == 0) {
                this.$message({
                    type: 'warning',
                    message: '请先选取数据',
                })
            } else {
                this.$confirm('是否删除所选中的线路车辆分配?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        let option = this.batchdeleteList
                        this.deleteRecord(option)
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        })
                    })
            }
        },
        deleteRecord(supplierIdAry) {
            this.$http
                .post('/routeRelVehicle/delete', supplierIdAry)
                .then(data => {
                    if (data.code == '0') {
                        this.$message.success(data.msg)
                        this.getList()
                    } else {
                        this.$message.error(data.msg)
                    }
                })
        },
        onexport() {
            console.log('onexport')
        },
        onimport() {
            console.log('onimport')
        },
    },
}
</script>
<style lang='scss' scoped>
</style>
